import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import * as StM from '../models/store';
import * as ActM from '../actions';
import * as SrvM from '../services';
import { IGlobalStoreState } from '../models/store';

interface IBannerProps {
    isShown: boolean;
    club: StM.IClubStoreState;
    banner: string;

    close: () => void;
    open: () => void;
}

interface IBannerState {
}

class Banner extends React.Component<IBannerProps, IBannerState> {
    
    private routeSrv: SrvM.RouteService = new SrvM.RouteService();

    constructor(props: IBannerProps) {
        super(props);
    }

    render() {
        const isClubDashboard = this.routeSrv.isActivePage(StM.Pages.ClubDashboard);
        const isScoreboardPage = this.routeSrv.isActivePage(StM.Pages.Scoreboard);
        const isVideoPage = this.routeSrv.isActivePage(StM.Pages.Video);
        if (isClubDashboard || isScoreboardPage || isVideoPage) { return null; }

        const mainBanner = this.props.club.mainBanner;
        const classes = ClassNames('banner', {
            'small': !this.props.isShown,
            'custom': mainBanner && mainBanner.logoId,
            'default': this.props.club.clubTimes.length && !mainBanner.logoId
        });
        const bannerAlternateText = mainBanner && mainBanner.alternativeText ? mainBanner.alternativeText : '';

        return (
            <div className={classes}>
                {mainBanner && this.renderIframe(mainBanner)}
                <div className="btn-banner-close" onClick={(e) => this.closeClick(e)}>Close</div>
                <p onClick={(e) => this.openClick(e)}>{bannerAlternateText}</p>
            </div>
        );
    }

    private renderIframe(banner: IClubBannerDto) {
        return (
            <iframe
                className="banner-frame"
                srcDoc={this.getCustomHtml(banner)}
            />
        ); 
    }
    
    private getCustomHtml(banner: IClubBannerDto) {
        const mainBanner = banner;
        const useAltImageUrl = mainBanner.useAlternativeImageUrl;
        const mainBannerUrl = mainBanner.logoId;
        const altBannerUrl = mainBanner.alternativeImageUrl;
        const redirectUrl = mainBanner.redirectUrl || '#';
        const imageUrl = useAltImageUrl ? altBannerUrl : mainBannerUrl ? `/api/v2/blobs/${mainBannerUrl}/content` : null;

        const jsxContent = (
            <>
                <body style={{ margin: 0, padding: 0, overflow: 'hidden' }}>
                    <a href={redirectUrl} target="_blank">
                        <img 
                            style={{ width: '100%', height: '100%' }} 
                            src={imageUrl}
                        />
                    </a>
                </body>
            </>
        );

        return ReactDOMServer.renderToStaticMarkup(jsxContent);
    }

    openClick(e: any) {
        if (e) e.stopPropagation();
        this.props.open();
    }

    closeClick(e: any) {
        this.props.close();
    }
}

const mapStateToProps = (state: IGlobalStoreState, ownProps: any) => {
    return {
        isShown: state.banner.isShown,
        club: state.club,
        banner: state.banner.mainClubBanner
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        close: () => dispatch(ActM.BannerActions.close()),
        open: () => dispatch(ActM.BannerActions.open())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
